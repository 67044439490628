import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '../store/index';
import {mergeObjects} from "@/utils/utils";
import metrika from "@/utils/metrika";
import Config from "@/config";
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});


router.beforeEach((to, from, next) => {

    store.commit("setRouteName", to.name);

    const loaded = {user: true, page: false};
    const setLoaded = (mode) => {
        loaded[mode] = true;
        if (loaded.user && loaded.page) next(); //переход на отдельную страницу, перед ней можно все хардкодить, либо в ее if
    };

    // загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
    if (to.meta && to.meta.api) {
        let params = mergeObjects({}, {action: to.meta.api, method: to.meta.method, params: to.params}, to.meta);
        if (to.meta.query) {
            //console.log("META QUERY", to.meta.query);
            // forward GET arguments to API request
            params.query = {};
            Object.keys(to.meta.query).map(key => {
                //console.log("KEY " + key + " maps from " + to.meta.query[key]);
                params.query[key] = to.query[to.meta.query[key]];
            });
            //console.log("PARAMS QUERY", params.query);
        }
        store.dispatch('fetchPage', params)
            .then(() => {
                setLoaded("page");
            })
            .catch((err) => {
                if (err?.code === Config.ERROR_CODE_FORBIDDEN) {
                    router.replace({name: 'auth'});
                }
                else if (err?.code) {
                    router.replace({name: 'error', params: {code: err.code}});
                }
                //next();
            });
    } else setLoaded("page");
});


router.afterEach((to, from) => {

    store.state.hasChanges = false;

    // небольшая задержка, чтобы в браузере сменился URL
    setTimeout(() => {
        // сообщаем Метрике явно, на какой URL пришли:
        //metrika.hit();

        // scroll to top
        window.scrollTo({
            'behavior': 'auto', //'smooth',
            'left': 0,
            'top': 0
        });

        // сообщаем в ГТМ, что страница доступна
        // if(typeof dataLayer) dataLayer.push({'event': 'render-event'});
    }, 500);

    // сообщаем пререндереру, что страница загрузилась:
    setTimeout(() => {
        document.dispatchEvent(new Event('render-event'));
    }, 1000);
});

export default router;
