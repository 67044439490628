import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vueFilters from './utils/vueFilters';
import vuetify from './plugins/vuetify';
import './styles/index.scss';

Vue.use(vueFilters);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
